import React, { useState, useEffect } from 'react';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import DataTable from 'react-data-table-component';
import { ActivityForUser } from '../../actions/activityAction';
import { useLocation } from '@reach/router';
import LoadingSpinner from '../loader';

const Users = ({ sid }) => {
  const location = useLocation();
  const url = decodeURI(location.search);
  const params = new URLSearchParams(url);
  let idfromURL = params.get('id');
  if (sid != null) {
    idfromURL = sid;
  }
  const [rows, setRows] = useState([]);
  const [originalRows, setOriginalRows] = useState([]);
  const [isLoading, showLoader] = useState(true);
  console.log('url response', idfromURL);

  const tableColumns = [
    {
      name: 'S.No.',
      sortable: false,
      grow: 0.2,
      cell: (row, index, column, id) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      name: 'Action',
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: 'Date',
      sortable: true,
      cell: (row) => {
        const event = new Date(row.when);
        const event1 = event.toDateString();
        return <div>{event1}</div>;
      },
    },
    {
      name: 'Activity',
      selector: (row) => row.message,
      sortable: true,
      wrap: true,
      grow: 3,
    },
  ];

  useEffect(() => {
    try {
      const response = ActivityForUser(`${idfromURL}`);
      const data = response?.data;
      console.log('getting the details for the user ', data);
      setRows(data);
      setOriginalRows(data);
      showLoader(false);
    } catch (error) {
      // setError('Something went wrong!');
      showLoader(false);
    }
  }, []);

  const handleInputChange = (event: any) => {
    const value = event.target.value.trim();
    const temp_rows = originalRows.filter((e) => JSON.stringify(e).indexOf(value.toLowerCase()) >= 0);
    setRows(temp_rows);
  };

  const forUser = (
    <>
      <InputGroup size="Small" status="Info">
        <input type="text" placeholder="Search..." onChange={(event) => handleInputChange(event)} />
      </InputGroup>
      <div
        style={{
          textAlign: 'center',
          backgroundColor: 'whitesmoke',
          color: 'black',
          padding: '18px',
          fontSize: '25px',
        }}
      >
        Actions Performed For User
      </div>
      <DataTable
        title="Complaint List"
        columns={tableColumns}
        data={rows}
        pagination
        paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
        paginationPerPage={100}
        striped
        dense
        noHeader
        highlightOnHover
      />
    </>
  );
  return <>{isLoading ? <LoadingSpinner /> : forUser}</>;
};

export default Users;
